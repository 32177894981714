// Video content

.home-animation {
  position: relative;
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.header-content {
  position: absolute;
  z-index: 10;
  width: 72%;
  transition: all 0.3s ease;

  h1 {color: $color-red; transition: all 0.5s ease;}
  h3 {margin-bottom: 10%; display: block;color: $color-white;}
  span {color: $color-white;}
}

@media (max-width: 1300px) {
  .header-content {width: 78%;}
  .header-content h1 {font-size: 4.75em;}
  .header-content h3 {font-size: 1.625em; margin-bottom: 4%;}
}

@media (max-width: 1024px) {
  .header-content {width: 96%;}
  .header-content h1 {font-size: 4em;}
}

@media (max-width: 840px) {.header-content h1 {font-size: 3.375em;}}

@media (max-width: 688px) {
  .header-content {width: 100%;}
  .header-content h1 {line-height: 1.2em; letter-spacing: -1px; font-size: 2.75em;}
  .header-content h3 {font-size: 1.25em;}
}


#bm {
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin-top: 2%;
}

@media (max-width: 1024px) {#bm {margin-top: 3%;}}
@media (max-width: 688px) {#bm {width: 106%; margin-top: 4%;}}


.video-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0;
  margin-left: 80px;
  margin-right: 80px;
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 80px);

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    mix-blend-mode: multiply;
  }
}

@media (max-width: 1040px) {.video-wrap {margin-left: 50px; margin-right: 50px; height: calc(100vh - 48px);}}
@media (max-width: 870px) {.video-wrap {margin-left: 45px; margin-right: 45px; height: calc(100vh - 68px);}}
@media (max-width: 688px) {.video-wrap {margin-left: 22px; margin-right: 22px;height: calc(100vh - 0px);}}
