.error-info {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}

.error-text {
  text-align: center;
  transition: all 0.5s ease;
  h2 {margin-bottom: 20px; font-size: 3.2em;}
  h3 {font-size: 1.6em;}

  @media (max-width: 1040px) {
    h2 {margin-bottom: 16px; font-size: 2.8em;}
    h3 {font-size: 1.4em;}
  }
  @media (max-width: 870px) {
    h3 {margin-bottom: 50px; font-size: 1.4em;}
  }
  @media (max-width: 688px) {
    max-width: 300px;
    h2 {margin-bottom: 16px; font-size: 1.8em;}
    h3 {font-size: 1.1em;}
  }
}

#errorGraphic {
  position: relative;
  width: 600px;
  margin-top: -40px;
  @media (max-width: 1040px) {width: 500px;}
  @media (max-width: 688px) {margin-top: 0; width: 290px;}
}
