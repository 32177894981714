#loader-wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
}

#preloader {
  display: block;
  position: relative;
  width: 112px;
  height: 100px;
  z-index: 1001;
}

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: $color-background;
  z-index: 1000;

  &.section-left {left: 0;}
  &.section-right {right: 0;}
}

.loaded {
  #loader-wrapper .loader-section {
    &.section-left {transform: translateX(-100%); transition: all 0.6s 0.3s ease-out;}
    &.section-right {transform: translateX(100%); transition: all 0.6s 0.3s ease-out;}
  }

  #preloader {opacity: 0; transition: all 0.3s ease-out;}

  #loader-wrapper {visibility: hidden; transform: translateY(-100%); transition: all 0.3s 0.9s ease-out;}
}

.logo-blue {
  fill: $color-blue;
  clip-path: inset(0px 0px 0px 0px);
  animation: mask1 3s infinite;
}

.logo-blue2 {fill: $color-blue; animation: opacity1 3s infinite;}

.logo-red {fill: $color-red; animation: mask2 3s infinite;}

@keyframes mask1 {
  0% {clip-path: inset(0px 0px 0px 0px);}
  50% {clip-path: inset(0px 0px 100px 0px);}
  100% {clip-path: inset(0px 0px 100px 0px);}
}

@keyframes mask2 {
  0% {clip-path: inset(0px 0px 0px 0px);}
  50% {clip-path: inset(0px 0px 0px 0px);}
  100% {clip-path: inset(0px 0px 100px 0px);}
}

@keyframes opacity1 {
  0% {opacity: 0;}
  50% {opacity: 0;}
  51% {opacity: 1;}
  100% {opacity: 1;}
}
