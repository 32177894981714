.thankyou-info {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}

.thankyou-text {
  text-align: center;
  transition: all 0.5s ease;
  h1  {margin-bottom: 20px; font-size: 4.75em;}
  h3 {font-size: 1.6em;}
  
  @media (max-width: 1040px) {
    h1 {margin-bottom: 16px; font-size: 4em;}
    h3 {font-size: 1.4em;}
  }
  @media (max-width: 870px) {h3 {font-size: 1.4em;}}
  @media (max-width: 688px) {
    max-width: 300px;
    h1 {margin-bottom: 12px; font-size: 3em;}
    h3 {font-size: 1.1em;}
  }
}

#thankyouGraphic {
  position: relative;
  width: 480px;
  margin-top: -90px;
  @media (max-width: 1040px) {width: 430px;}
  @media (max-width: 688px) {width: 290px;}
}
