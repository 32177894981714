
// About page

@media (max-width: 688px) {
  .main-content-about {margin: 0;}
}

.about-header {width: 100%; overflow: hidden;}

#aboutHeader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

#aboutAnimation {
  width: 78%;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin-top: 1px;
  transition: all 0.3s ease;
  @media (max-width: 1300px) {width: 90%;}
  @media (max-width: 1024px) {width: 108%; margin-top: -20px;}
  @media (max-width: 688px) {width: 120%; margin-top: -30px; margin-left: 4px;}
}


// About Button

.contactAbout-btn {
  left: 14px;
  @media (max-width: 1040px) {left: -6px;}
}

@media (max-width: 688px) {.work-btn-about, .contactAbout-btn {visibility: hidden;}}

.aboutTitle {
  position: absolute;
  top: 44%;
  color: $color-blue;
  line-height: 1.1em;
  transition: all 0.5s ease;
  @media (max-width: 1380px) {font-size: 4.4em;}
  @media (max-width: 688px) {font-size: 2.8em;}
  @media (max-width: 480px) {font-size: 2.8em;}
}

.anchor-point {
  position: absolute;
  width: 200px;
  height: 1px;
  bottom: -2px;
}

// Logo Panel

.logo-grid {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  opacity: 0;
  bottom: -80px;
  transition: all 1.3s ease-in-out;
  @media (max-width: 688px) {margin-left: 8px;margin-right: 8px;}
}

.client-logo-box {
  display: flex;
  margin-top: 20px;
  width: 12%;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  @media (max-width: 1180px) {width: 20%;}
  @media (max-width: 870px) {width: 20%;}
  @media (max-width: 688px) {width: 33%; margin-top: 10px;}
}

.client-logo {
  width: 82%;
  @media (max-width: 688px) {width: 80%;}
}

.clients-description {
  position: relative;
  margin-bottom: 20px;
  right: -100px;
  opacity: 0;
  transition: all 1.2s ease-in-out;
  @media (max-width: 1380px) {margin-left: 36%;}
  @media (max-width: 980px) {margin-bottom: 10px; margin-left: 40%;}
  @media (max-width: 1020px) {margin-left: 0;}
  @media (max-width: 870px) {padding-top: 0px;}
}


// Awards Panel

.awards {
  margin-left: 22%;
  transition: all 0.5s ease;
  @media (max-width: 1380px) {margin-left: 25%;}
  @media (max-width: 1040px) {margin-left: 26%;}
  @media (max-width: 1020px) {margin-left: 0;}
  @media (max-width: 870px) {margin-right: 20px;}
  @media (max-width: 688px) {margin-right: 5px;}
}

.award-grid {
  display: flex;
  flex-wrap: wrap;
  transition: all 0.5s ease;
  p {line-height: 1.2em;}
  @media (max-width: 1040px) {p {line-height: 1em;}}
}

.award-list {
  margin-bottom: 40px; width: 25%;
  @media (max-width: 1140px) {p, h5 {font-size: 0.875em;}}
  @media (max-width: 2040px) {width: 24% !important;}
  @media (max-width: 1900px) {width: 28% !important; margin-right: 28px;}
  @media (max-width: 1040px) {margin-bottom: 18px; width: 46%;}
  @media (max-width: 870px) {margin-right: 30px;}
  @media (max-width: 688px) {width: 40% !important; margin-bottom: 10px; margin-right: 28px;}
}

.campaign-name {margin-bottom: 10px !important;}

.award-title {font-size: 1em;margin-top: 6px;}

.award-category {
  margin-top: 6px; padding-bottom: 14px;
  @media (max-width: 1140px) {font-size: 0.875em; margin-bottom: 8px!important;}
}

.box {
  position: absolute;
  display: flex;
  width: 200px;
  height: 200px;
  background-color: red;
  align-items: center;
  justify-content: center;
  z-index: 80;
}

.about-graphic {position: absolute;}

.about-graphic1 {
  width: 132px;
  right: 70px;
  top: -68px;
  transition: width 0.5s ease;
  @media (max-width: 1100px) {width: 124px;top: -64px;}
  @media (max-width: 870px) {width: 110px; top: -56px;}
  @media (max-width: 688px) {width: 90px; top: -46px; right: 44px;}
}

.about-graphic2 {
  width: 140px;
  bottom: -60px;
  left: 90px;
  transition: width 0.5s ease;
  @media (max-width: 1100px) {width: 124px; bottom: -58px;}
  @media (max-width: 870px) {display: none;}
}

.about-graphic3 {
  width: 142px;
  right: 90px;
  top: -61px;
  transition: all 0.5s ease;
  @media (max-width: 1100px) {width: 124px; top: -53px;}
  @media (max-width: 870px) {width: 110px;top: -47px;}
  @media (max-width: 688px) {width: 90px; top: -38px; right: 44px;}
}

.about-graphic4 {
  width: 140px;
  top: 950px;
  left: 110px;
  transition: all 0.5s ease;
  @media (max-width: 1180px) {width: 124px;left: 90px;}
  @media (max-width: 1020px) {display: none;}
}

.about-graphic5 {
  width: 142px;
  right: 70px;
  bottom: 50px;
  transition: all 0.5s ease;
  @media (max-width: 1200px) {width: 124px; right: 30px; bottom: 30px;}
  @media (max-width: 870px) {width: 110px; right: 40px; bottom: 20px;}
  @media (max-width: 688px) {display: none;}
}

.titleStory, .titleSkills, .titleExperience, .titleRecognition {
  position: relative;
  left: -100px;
  opacity: 0;
  transition: all 1.2s ease-in-out;
  @media (max-width: 480px) {left: 0px;opacity: 1;}
}

@media (max-width: 688px) {.titleStory {margin-top: 20px;}}

.story-description, .content-skills, .awards {
  position: relative;
  right: -100px;
  opacity: 0;
  transition: all 1.2s ease-in-out;
  @media (max-width: 480px) {right: 0px;opacity: 1;}
}

.skills-section, .awards-section {position: relative; opacity: 0; transition: all 1.3s ease;}

.fadein {opacity: 1;}

.fadeinLeft {left: 0px;opacity: 1;}

.fadeinRight {right: 0px;opacity: 1;}

.fadeinBottom {bottom: 0px;opacity: 1;}
