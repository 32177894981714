@import "fonts";
@import "base";
@import "loader";
@import "scroll";
@import "navbar";
@import "homepage";
@import "work";
@import "project";
@import "content";
@import "about";
@import "contact";
@import "thankyou";
@import "404";