$color-background: #F2F4F7;
$color-blue: #0048FF;
$color-red: #FD1F6E;
$color-white: #FFF;

@mixin ProximaNova-Extrabld($colour: $color-blue, $fw: normal, $ls: normal, $fs: normal) {
    font-family: 'ProximaNova-Extrabld', 'sans-serif';
    @if $colour { color: $colour; }
    @if $fw { font-weight: $fw; }
    @if $fs { font-style: $ls; }
    @if $ls { letter-spacing: $ls; }
}

@mixin ProximaNova-Bold($colour: $color-blue, $fw: normal, $ls: normal, $fs: normal) {
    font-family: 'ProximaNova-Bold', 'sans-serif';
    @if $colour { color: $colour; }
    @if $fw { font-weight: $fw; }
    @if $fs { font-style: $ls; }
    @if $ls { letter-spacing: $ls; }
}

@mixin ProximaNova-Regular($fw: normal, $ls: normal, $fs: normal) {
    font-family: 'ProximaNova-Regular', 'sans-serif';
    @if $fw { font-weight: $fw; }
    @if $fs { font-style: $ls; }
    @if $ls { letter-spacing: $ls; }
}

@mixin ProximaNova-Light($fw: normal, $ls: normal, $fs: normal) {
    font-family: 'ProximaNova-Light', 'sans-serif';
    @if $fw { font-weight: $fw; }
    @if $fs { font-style: $ls; }
    @if $ls { letter-spacing: $ls; }
}


// Page Styles

*{box-sizing: border-box;}

html, body {
  background-color: $color-background;
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow-x: hidden;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -o-text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

body {
  @include ProximaNova-Extrabld;
  color: $color-blue;
}

// Fonts Sizes

h1, h2, h4 {
  @include ProximaNova-Extrabld;
  padding: 0;
  margin: 0;
}

h3, h5, a {
  @include ProximaNova-Bold;
  padding: 0;
  margin: 0;
}

h1 {font-size: 5.625em;}
h2 {font-size: 3.75em;}
h3 {font-size: 1.875em;}
h4 {font-size: 1.2em;}
h5 {font-size: 0.875em;}
a {font-size: 1.2em;}

p {
  @include ProximaNova-Light;
  margin: 0; font-size: 1em; line-height: 1.6em;
}

@media (max-width: 1040px) {
  h2 {font-size: 2.1em;}
  h4 {font-size: 1.125em;}
  a {font-size: 1.125em;}
  p {font-size: 0.875em;}
}

@media (max-width: 688px) {h3 {font-size: 1.7em;}}


// Navbar

.navbar-container {
  top: 0;
  position: fixed;
  z-index: 20;
  width: 100%;
  background: $color-background;
  padding-left: 80px;
  padding-right: 80px;
  height: 94px;
}

@media (max-width: 1040px) {
  .navbar-container {
    padding-left: 50px;
    padding-right: 50px;
    height: 80px;
  }
}

@media (max-width: 870px) {
  .navbar-container {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media (max-width: 688px) {
  .navbar-container {
    padding-left: 22px;
    padding-right: 22px;
    height: 54px;
  }
}


// Main Content

.container {height: 100vh;}


// Buttons

.left-btn, .right-btn {
  position: fixed;
  top: 50%;
  z-index: 15;
  background: $color-background;
}

.left-btn {
  left: 24px;
  transform: rotate(-90deg);
  transform-origin: center center;
}

.right-btn {
  right: 28px;
  transform: rotate(90deg);
  transform-origin: center center;
}

@media (max-width: 1040px) {.left-btn {left: 4px;} .right-btn {right: 8px;}}

@media (max-width: 870px) {.left-btn {left: 2px;} .right-btn {right: 4px;}}

@media (max-width: 688px) {.left-btn, .right-btn {display: none;}}


// Header

.header {
  background: $color-blue;

  h1 {color: $color-background;}
}


// Footer

.footer {
  background: $color-background;
  height: 80px;
  padding: 0px 80px 0px 80px;
  z-index: 3;
}

.footer-home {
  width: 100%;
  bottom: 0;
  position: fixed;
  padding-top: 16px;
}

.footer-top {margin-top: 20px;}


@media (max-width: 1040px) {
  .footer {height: 64px;padding-left: 50px;padding-right: 50px;}
  .footer-home {padding-top: 12px;}
}

@media (max-width: 870px) {
  .footer {height: 68px; padding-left: 45px;padding-right: 45px;}
  .footer-top {margin-top: 12px;}
}

@media (max-width: 688px) {
  .footer {height: 46px; padding-left: 22px; padding-right: 22px;}
  .footer-home {padding-top: 10px;}
  .footer-top {margin-top: 10px;}
  .footer-contact {overflow: hidden; position: relative; bottom: 0; height: 50px;}
  //.footer-thankyou {bottom: 20px;}
}


.social-footer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 13%;
  width: 100%;
  height: 75%;
  z-index: 2;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-top: 2px;
    font-size: 2.2em;
  }

  li {padding: 10px;}

  a {text-decoration: none;color: $color-white;}

  .footer-social-links {
    width: 200px;
    height: 200px;
    background-color: $color-red;
  }

  .social-footer-links {position: relative;}
}

@media (max-width: 688px) {
  .social-footer ul {font-size: 1.4em;}
  .social-footer li {padding: 6px;}
}

.social-footer-popup {
  display: none;
  position: relative;
  text-align: center;
  width: 700px;
  height: 600px;
  background-color: $color-red;

  ul {top: 125px;}

  .social-close-btn::before {
    content: '';
    padding: 0;
    position: absolute;
    margin-top: 12px;
    margin-left: -2px;
    height: 16px;
    background-color: $color-blue;
    z-index: -1;
    transition: all .3s ease;
    width: 0%;

    &:hover::before {width: 105%;}
  }
}

@media (max-width: 1040px) {.social-footer-popup {width: 460px;}}

@media (max-width: 688px) {
  .social-footer-popup {width: 290px; height: 380px; ul {top: 84px;}}
  .social-footer-popup .social-close-btn::before {height: 12px; &:hover::before {width: 110%;}}
}

.social-footer-links a {
  &::before {
    content: '';
    padding: 0;
    position: absolute;
    margin-top: 26px;
    margin-left: -4px;
    height: 20px;
    background-color: $color-blue;
    z-index: -1;
    transition: all .3s ease;
    width: 0%;
  }

  &:hover::before {width: 30%;}
}

.social-graphic1 {position: absolute; bottom: -26px; left: 50px; width: 180px;}

.social-graphic2 {position: absolute; top: -40px; left: 80px; width: 140px;}

.social-graphic3 {position: absolute; top: 470px; right: -90px; width: 140px;}

.footer-popup-active {display: flex; justify-content: center;}

.social-close-btn {position: absolute; top: 20px; right: 22px;}


@media (max-width: 1040px) {
  .social-graphic1 {bottom: -24px; left: 40px; width: 160px;}
  .social-graphic2 {top: -28px; left: 60px; width: 100px;}
  .social-graphic3 {top: 496px; right: -70px; width: 110px;}
}

@media (max-width: 688px) {
  .social-graphic1 {bottom: -22px; left: 28px; width: 120px;}
  .social-graphic2 {top: -20px; left: 40px; width: 70px;}
  .social-graphic3 {display: none;top: 250px;}
  .social-close-btn {top: 16px;right: 18px;font-size: 1.1em !important;}
}


.btn {
  margin: 0;
  padding: 0;
  font-size: 1.375em;
  text-decoration: none;
  color: $color-blue;
  z-index: 1;
  cursor: pointer;
  transition: all 0.1s ease;

  &::before {
    content: '';
    padding: 0;
    position: absolute;
    margin-top: 13px;
    margin-left: -2px;
    height: 10px;
    background-color: $color-red;
    z-index: -1;
    transition: all .3s ease;
    width: 0%;
  }

  &:hover::before {width: 110%;}
  &:focus {outline: none;}

  @media (max-width: 1040px) {font-size: 1.17em;}
}

.social-btn, .disclaimer {padding: 0;}

.social-btn, .project-btn {
  position: absolute;
  font-size: 0.875em;
  margin-top: 2px;
  width: 114px;
  color: $color-blue;
  text-decoration: none;

  &::before {margin-top: 8px;}
}

.social-btn {
  &:hover::before {transition: all .3s ease; width: 120px;}
}

.project-btn {
  &:hover::before {transition: all .3s ease;width: 120px;}

  @include ProximaNova-Regular;
  width: 80px;

  &::before {margin-top: 10px;}
  &:hover::before {width: 48px;}
}

@media (max-width: 688px) {
  .social-btn, .project-btn {margin-top: 0px;}
  .social-btn-main {display: none; visibility: none;}
}

.project-btn-next {margin-left: 56px;}

.disclaimer {
  position: absolute;
  float: right;
  right: 80px;
  margin: 0;
  margin-top: 2px;

  @media (max-width: 1040px) {right: 50px;}
  @media (max-width: 870px) {right: 45px;}
}

@media (max-width: 688px) {
  .disclaimer {display: none;}
  .disclaimer-main {display: block; right: 22px; font-size: 0.875em;}
}

.contact-btn {
  display: block;
  margin: auto;
  padding: 0;
  width: 120px;
  text-align: center;

  &:hover::before {transition: all .3s ease; width: 82px;}
}

@media (max-width: 688px) {
  .contact-btn {
    position: absolute;
    float: right;
    text-align: right;
    right: 22px;
    font-size: 0.875em;

    &::before {margin-top: 9px;}
    &:hover::before {transition: all .3s ease; width: 56px;}
  }

  .contact-btn-ty {display: none;}
}
