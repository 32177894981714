// content

.main-content {
  display: block;
  margin: 0px 80px 0px 80px;
  outline: none;

  img {
    margin-bottom: 26px; 
    //&:last-child {margin-bottom: 14px;}
  }

  @media (max-width: 1040px) {margin: 0px 60px 0px 60px; img {margin-bottom: 20px;}}
  @media (max-width: 870px) {margin: 0px 45px 0px 45px; img {margin-bottom: 14px;}}
  @media (max-width: 688px) {margin: 0px 22px 0px 22px; img {margin-bottom: 6px;}}
}

.content-info {
  margin-left: calc(14% - 54px);
  margin-right: calc(14% - 54px);
  padding-top: 90px;
  padding-bottom: 90px;
  outline: none;

  @media (max-width: 870px) {padding-top: 60px; padding-bottom: 48px;}
  @media (max-width: 688px) {margin-left: 0; margin-right: 0; padding-top: 42px; padding-bottom: 22px;}
}

.content-info-padding {
  padding-bottom: 150px;
  @media (max-width: 1080px) {padding-bottom: 90px;}
  @media (max-width: 870px) {padding-bottom: 60px;}
  @media (max-width: 688px) {padding-bottom: 40px;}
}

.content-info-block {
  padding-left: calc(14% - 54px);
  padding-right: calc(14% - 54px);
  padding-top: 90px;
  padding-bottom: 90px;

  @media (max-width: 870px) {padding-top: 60px; padding-bottom: 60px;}

  @media (max-width: 688px) {
    margin-left: 0;
    margin-right: 0;
    padding-top: 50px;
    padding-bottom: 40px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.content-info-about {outline: none;}

.content-red {background-color: $color-red;}

.content-blue {background-color: $color-blue;}

.content-red, .content-blue {h3, h5, p {color: $color-white;}}

.content-text {display: flex;}

.context-text-intro {margin-bottom: 20px;}

.content-details {position: absolute; margin-bottom: 14px; dl {margin: 0; padding: 0; line-height: 1.6em;}}

.content-description {margin-left: 28%; transition: all 0.5s ease; p {margin-bottom: 20px;}}

.content-row1 {margin-bottom: 90px;}

.col-3 {display: flex; p {line-height: 1.2em;}}

.content-title {font-size: 1em; margin-top: 6px;margin-bottom: 30px;}

.col-list {width: 280px; transition: all 0.5s ease;}


@media (max-width: 1380px) {
  .content-description {margin-left: 24%;}
  .col-list {width: 216px;}
}

@media (max-width: 1200px) {.content-details h3 {font-size: 1.7em;}}

@media (max-width: 1100px) {.col-list {width: 196px;}}

@media (max-width: 1020px) {
  .col-list {width: 216px;}
  .content-text { display: block;}
  .content-details {position: inherit; width: auto; margin-bottom: 24px;}
  .content-description {padding-top: 0px; margin-left: 0; margin-right: 20px;}
}

@media (max-width: 870px) {
  .content-text { display: block;}
  .content-details {position: inherit; width: auto; margin-bottom: 24px;}
  .content-row1 {margin-bottom: 40px;}
  .content-title {margin-bottom: 16px;}
  .col-list {width: 216px;}
  .context-text-intro {margin-bottom: 0;}
  .content-info-section {padding-top: 40px;}
}

@media (max-width: 688px) {
  .content-details {margin-bottom: 16px;}
  .context-text-intro {margin-bottom: 0;}
  .content-description {margin-right: 5px;}
  .col-list {width: 30%;margin-right: 20px;}
  .content-info-section {padding-top: 20px;}
}

@media (max-width: 580px) {.col-list p {line-height: 1.4em; span {display: none;}}}
