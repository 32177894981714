@import "components/grids";
// Project content

.navbar-project, .background-show, .project-website-title, .website-title-blue, .logo-project {
  transition: all .3s ease;
}
.navbar-project {background: 0;}

.background-show {background: $color-background;}

.project-website-title {color: $color-white;}

.website-title-blue {color: $color-blue;}

.btn-container-project span {background: $color-white;}

.project-menu span {background: $color-blue;}

.logo-project {fill: $color-white;}

.logo-project-scroll {fill: $color-red;}

.left-btn-project {
  left: 8px;
  @media (max-width: 1040px) {left: 6px;}
  @media (max-width: 688px) {display: none;}
}

.back-btn {
  position: fixed;
  top: 18px;
  left: -7px;
  color: $color-white;
  font-size: 0.9em;
  letter-spacing: .6px;
  font-family: "ProximaNova-Regular", "sans-serif";

  &::before {margin-top: 10px;}

  @media (max-width: 1040px) {left: -12px;top: 12px;}
}

.back-btn-blue {color: $color-blue;}

.back-line {
  position: fixed;
  transform: rotate(90deg);
  width: 24px;
  height: 1px;
  left: 0px;
  background: $color-white;

  @media (max-width: 1040px) {width: 14px;}
}

.back-line-blue {background: $color-blue;}

.back-btn-scroll {transition: all .3s ease; color: $color-blue;}

.back-line-scroll {transition: all .3s ease; background: $color-blue;}

.scroll-btn {
  position: absolute;
  margin-left: -10px;
  bottom: 122px;
  transform: rotate(-90deg);
  color: $color-white;
  @include ProximaNova-Regular;
  font-size: 0.9em;

  &::before {margin-top: 10px;}

  @media (max-width: 1040px) {&::before {display: none;}}
  @media (max-width: 688px) {bottom: 92px;}
}

.scroll-btn-about {color: $color-blue;z-index: 18;}

.scroll-line {
  position: absolute;
  width: 1px;
  height: 160px;
  bottom: 0;
  background: $color-white;

  @media (max-width: 688px) {height: 130px;}
}

.scroll-line-about {background: $color-red;}

.scroll-line-top {
  background: $color-blue;
  z-index: 2;
  animation: scrollLine 4s infinite;
}

.scroll-btn, .scroll-line, .scroll-line-top {
  left: 14%;
  @media (max-width: 688px) {left: 28px;}
}

@keyframes scrollLine {
  0% {bottom: 160px; height: 0px;}
  50% {bottom: 0px; height: 160px;}
  100% {bottom: 0px; height: 0px;}
}

@media (max-width: 688px) {
  @keyframes scrollLine {
    0% {bottom: 130px;height: 0px;}
    50% {bottom: 0px;height: 130px;}
    100% {bottom: 0px;height: 0px;}}
}

.project-header {
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  background-size: cover !important;
  background-position: center center !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  opacity: 0;

  @media (max-width: 1040px) {background-attachment: scroll;}
  @media (max-width: 688px) {height: 520px;}
}

@media (max-width: 1040px) {
  .project-header-right {background-position: 63% center;}
  .project-header-left {background-position: 8% center;}
}
@media (max-width: 870px) {.project-header-left {background-position: 30% center;}}
@media (max-width: 688px) {.project-header-bg {height: 490px;background-size: 130%;background-position: center top;}}
@media (max-width: 688px) {.about-header {height: 95vh;}}

.project-title {
  position: absolute;
  top: 38%;
  left: 14%;
  color: $color-white;
  line-height: 1.1em;
  @media (max-width: 1040px) {font-size: 4.4em;}
  @media (max-width: 688px) {font-size: 2.5em; left: 28px;}
}

.project-title-right {
  left: 48%;
  @media (max-width: 1040px) {left: 42%;}
  @media (max-width: 688px) {left: 40%;}
}

.title-1line {top: 44%;}
.title-3lines {top: 32%;}

.project-brand {
  margin-bottom: 65px;
  @media (max-width: 870px) {margin-bottom: 34px;}
  @media (max-width: 688px) {font-size: 1.65em; letter-spacing: -0.03em; margin-bottom: 24px;}
}

#project-detail {
  @include ProximaNova-Light; margin-top: 2px; margin-bottom: 28px;
  @media (max-width: 688px) {margin-top: 0px; margin-bottom: 22px;}
}

.project-image {width: 100%;height: auto;}

.project-img-centre {
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-top: 3%;
  margin-bottom: 5%;
  transition: all 0.4s ease-in-out;

  img {
    box-shadow: 0 0.8rem 2rem rgba(0, 0, 0, 0.3);
    width: 42%;
    transition: all 0.4s ease-in-out;
  }

  @media (max-width: 1040px) {
    margin-top: 4%; margin-bottom: 6%;
    img {width: 60%;}
  }

  @media (max-width: 688px) {
    margin-top: 6%; margin-bottom: 7%;
    img {width: 68%; box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.3);}
  }
}

#project-img-wide {
  width: 60%;
  @media (max-width: 1040px) {width: 72%;}
  @media (max-width: 688px) {width: 82%;}
}

.project-img-first {
  margin-top: 6%;
  @media (max-width: 1040px) {margin-top: 8%;}
  @media (max-width: 688px) {margin-top: 10%;}
}

.project-overlay {position: relative;}

.project-img-overlay {
  position: absolute;
  width: 22% !important;
  margin-left: -15%;
  z-index: 4;

  @media (max-width: 1300px) {width: 26% !important;}
  @media (max-width: 1040px) {width: 30% !important; margin-left: -19%;}
  @media (max-width: 870px) {width: 34% !important; margin-left: -20%;}
  @media (max-width: 688px) {width: 44% !important;}
}

.video-container {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
  margin-bottom: 28px;

  @media (max-width: 688px) {margin-bottom: 18px;}
}

@media (max-width: 870px) {
  .video-container-last {margin-bottom: 0;}
  .video-container-first {margin-bottom: 20px;}
}

.video {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}

.vimeo-video {
  position: relative;margin-bottom: 40px;
  @media (max-width: 1040px) {margin-bottom: 30px;}
  @media (max-width: 870px) {margin-bottom: 22px;}
  @media (max-width: 688px) {margin-bottom: 14px;}
}

.info-project {
  margin-bottom: 38px;
  @media (max-width: 870px) {margin-bottom: 0px;}
}

.left-contact-btn {
  left: 20px;
  @media (max-width: 1040px) {left: 4px;}
}
