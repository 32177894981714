// Top Navbar

.website-title {
  margin: 0;
  position: absolute;
  padding-top: 44px;

  span {font-family: 'ProximaNova-Light', 'sans-serif'; letter-spacing: 0.6px;}
}

@media (max-width: 1040px) {.website-title {padding-top: 34px;}}

@media (max-width: 688px) {.website-title {display: none;}}


.logo-btn {
  display: block;
  padding: 0;
  margin: auto;
  padding-top: 22px;
  width: 56px;
  height: 49px;
  border: 0px solid;
  outline: 0;
  background: none;
  fill: $color-red;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    .website-logo, .logo-project {transition: all .3s ease; fill: $color-blue;}
  }
}

@media (max-width: 1040px) {.logo-btn {padding-top: 16px; width: 52px; height: 46px;}}

@media (max-width: 688px) {.logo-btn { margin-left: 0px; padding-top: 11px; width: 34px; height: 30px;}}


.website-logo {transition: all .3s ease; padding: 0; fill: $color-red;}

.button_container {
  position: fixed;
  top: 40px;
  right: 80px;
  height: 26px;
  width: 35px;
  z-index: 100;
  transition: all .1s ease;
  cursor: pointer;

  &:hover span {background: $color-red;}

  &.active {
    .top {
      transform: translateY(10px) translateX(0) rotate(45deg);
      background: $color-white;
    }
    .middle {
      opacity: 0;
      background: $color-white;
    }
    .bottom {
      transform: translateY(-10px) translateX(0) rotate(-45deg);
      background: $color-white;
    }
  }

  span {
    border: none;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .35s ease;
    background: $color-blue;
    cursor: pointer;

    &:nth-of-type(2) {top: 10px;}
    &:nth-of-type(3) {top: 20px;}
  }
}

@media (max-width: 1040px) {.button_container {top: 32px; right: 50px;}}
@media (max-width: 870px) {.button_container {right: 45px;}}
@media (max-width: 688px) {.button_container {height: 26px; width: 35px; top: 17px; right: 22px;}}

.overlay {
  position: fixed;
  background: $color-red;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, height .35s;
  overflow: hidden;
  z-index: 40;

  &.open {
    opacity: .97;
    visibility: visible;
    height: 100%;

    li {
      animation: fadeInRight .5s ease forwards;
      animation-delay: 0.35s;

      &:nth-of-type(2) {animation-delay: 0.4s;}
      &:nth-of-type(3) {animation-delay: 0.45s;}
      &:nth-of-type(4) {animation-delay: 0.5s;}
    }
  }

  nav {
    position: relative;
    height: 54%;
    font-size: 3.125em;
    text-align: center;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;
    width: 350px;

    li {
      display: block;
      height: 25%;
      height: calc(100% / 4);
      min-height: 50px;
      position: relative;
      opacity: 0;
      a {
        display: block;
        position: relative;
        font-family: 'ProximaNova-Extrabld', 'sans-serif';
        color: $color-white;
        text-decoration: none;
        overflow: hidden;

        &:hover:before, &:focus:before, &:active:before {
          width: 110%;
        }
        &:before {
          content: '';
          position: absolute;
          width: 0%;
          height: 28px;
          margin-top: 52px;
          margin-left: -14px;
          z-index: -1;
          transition: all .3s ease;
          background: $color-blue;
        }
      }
    }
  }
}

@media (max-width: 1040px) {
  .overlay nav {height: 50%; font-size: 2.8em;}
  .overlay ul {width: 290px;}
  .overlay ul li a:before {margin-top: 42px;height: 26px;}
}

@media (max-width: 688px) {
  .overlay nav {height: 48%; font-size: 1.8em;}
  .overlay ul {width: 190px;}
  .overlay ul li a:before {margin-top: 28px; height: 20px; margin-left: -10px;}
}

@keyframes fadeInRight {
  0% {opacity: 0; left: 20%;}
  100% {opacity: 1; left: 0;}
}
