@import url("//hello.myfonts.net/count/364c90");

@font-face {
  font-family: 'ProximaNova-Black';
  src: url("../fonts/364C90_0_0.eot");
  src: url("../fonts/364C90_0_0.eot?#iefix") format("embedded-opentype"), url("../fonts/364C90_0_0.woff2") format("woff2"), url("../fonts/364C90_0_0.woff") format("woff"), url("../fonts/364C90_0_0.ttf") format("truetype"), url("../fonts/364C90_0_0.svg#wf") format("svg");
}


@font-face {
  font-family: 'ProximaNova-Bold';
  src: url("../fonts/364C90_1_0.eot");
  src: url("../fonts/364C90_1_0.eot?#iefix") format("embedded-opentype"), url("../fonts/364C90_1_0.woff2") format("woff2"), url("../fonts/364C90_1_0.woff") format("woff"), url("../fonts/364C90_1_0.ttf") format("truetype"), url("../fonts/364C90_1_0.svg#wf") format("svg");
}


@font-face {
  font-family: 'ProximaNova-Extrabld';
  src: url("../fonts/364C90_2_0.eot");
  src: url("../fonts/364C90_2_0.eot?#iefix") format("embedded-opentype"), url("../fonts/364C90_2_0.woff2") format("woff2"), url("../fonts/364C90_2_0.woff") format("woff"), url("../fonts/364C90_2_0.ttf") format("truetype"), url("../fonts/364C90_2_0.svg#wf") format("svg");
}


@font-face {
  font-family: 'ProximaNova-Light';
  src: url("../fonts/364C90_3_0.eot");
  src: url("../fonts/364C90_3_0.eot?#iefix") format("embedded-opentype"), url("../fonts/364C90_3_0.woff2") format("woff2"), url("../fonts/364C90_3_0.woff") format("woff"), url("../fonts/364C90_3_0.ttf") format("truetype"), url("../fonts/364C90_3_0.svg#wf") format("svg");
}


@font-face {
  font-family: 'ProximaNova-Regular';
  src: url("../fonts/364C90_4_0.eot");
  src: url("../fonts/364C90_4_0.eot?#iefix") format("embedded-opentype"), url("../fonts/364C90_4_0.woff2") format("woff2"), url("../onts/364C90_4_0.woff") format("woff"), url("../fonts/364C90_4_0.ttf") format("truetype"), url("../fonts/364C90_4_0.svg#wf") format("svg");
}
