.work-header {
  display: flex;
  justify-content: space-between;

  h2 {
    padding: 0; margin: 160px 0 36px 80px;

    @media (max-width: 1040px) {margin: 120px 0 20px 50px;}
    @media (max-width: 870px) {margin: 110px 0 20px 45px;}
    @media (max-width: 688px) {margin: 70px 0 12px 22px;}
  }
}

.controls {
  left: 0;
  right: 0;
  margin-right: 80px;
  margin-top: 210px;

  a {
    position: relative;
    float: right;
    margin: 0;
    padding: 0;
    margin-left: 30px;
    font-size: 1.125em;
    font-family: 'ProximaNova-Bold', 'sans-serif';
    text-decoration: none;
    color: $color-blue;
    background: $color-background;
    border: none;
    outline: 0;
    z-index: 1;
    cursor: pointer;
    transition: all 0.1s ease;

    &::before {
      content: '';
      padding: 0;
      position: absolute;
      margin-top: 11px;
      margin-left: -2px;
      height: 10px;
      background-color: $color-red;
      z-index: -1;
      transition: all .3s ease;
      width: 0%;
    }
  }
  a--is-active::before, a:hover::before {
    transition: all .3s ease;
    width: 110%;
  }

  @media (max-width: 1040px) {
    margin-top: 142px; margin-right: 50px;
    a {font-size: 1.06em; margin-left: 20px;}
  }

  @media (max-width: 870px) {margin-top: 132px; margin-right: 45px;}
  @media (max-width: 688px) {display: none; margin-top: 70px; margin-right: 32px;}
}


.contactWork-btn {
  right: 14px;
  @media (max-width: 1040px) {right: 0px;}
  @media (max-width: 870px) {right: -6px;}
}

// Portfolio Grid

.portfolio-container {
  max-width: 100%;
  //height: 100%;
  margin: 0 80px 30px 80px;

  @media (max-width: 1040px) {margin: 0 50px 30px 50px;}
  @media (max-width: 870px) {margin: 0 45px 30px 45px;}
  @media (max-width: 688px) {margin: 0 22px 30px 22px;}
}

.portfolio-grid {display: flex; flex-wrap: wrap; justify-content: space-between;}

.project-big, .project-sml-blue, .project-sml-red {flex: 30%;}

.portfolio-grid {
  position: relative !important;
  display: grid !important;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-auto-flow: row dense;
  grid-gap: 20px;
  max-width: 100%;
  //min-height: 100%;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 15px;
  }

  @media (max-width: 870px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(11, 1fr);
    grid-gap: 12px;
  }

  @media (max-width: 688px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-gap: 12px;
  }
}

// Projects

.project-big, .project-sml-blue, .project-sml-red {
  position: relative;
  padding: 0;
  overflow: hidden;
  cursor: pointer;

  h3, h4 {@include ProximaNova-Bold;}
  h5 {@include ProximaNova-Regular;}

  h3, h4, h5 {
    position: absolute;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    color: $color-white;
  }
}

// Projects Big

.project-big {
  width: auto;
  grid-column: span 2;
  grid-row: span 2;
  background: $color-blue;
  transition: all 0.4s ease-in-out;

  h3, h4, h5 {margin: 40px;}

  h3, h5 {bottom: 0;}
  h4 {margin-top: 80px; font-size: 1.34em;}
  h3 {margin-bottom: 28px;}
  h5 {margin-bottom: -4px; font-size: 1.1em;}

  &:hover {
    .project-thumbnail {
      opacity: 0.07;
      filter: grayscale(80%);
      transition: all 0.4s ease-in-out;
    }

    h3, h4, h5 {opacity: 1;}
    h4 {margin-top: 40px;}
    h3 {margin-bottom: 82px;}
    h5 {margin-bottom: 48px;}
  }
}

@media (max-width: 1040px) {
  .project-big {
    h3, h4, h5 {margin: 30px;}
    h4 {margin-top: 60px;}
    h5 {margin-bottom: -4px;}

    &:hover {
      h3 {margin-bottom: 22px;}
      h4 {margin-top: 32px;}
      h3 {margin-bottom: 70px;}
      h5 {margin-bottom: 34px;}
    }
  }
}

@media (max-width: 688px) {
  .project-big {
    h3, h4, h5 {margin: 22px;}
    h4 {margin-top: 56px;}
    h3 {line-height: 28px; font-size: 1.5em; margin-bottom: 20px;}
    h5 {font-size: 1em; margin-bottom: -10px;}

    &:hover {
      h4 {margin-top: 24px;}
      h3 {margin-bottom: 58px;}
      h5 {margin-bottom: 24px;}
    }
  }
}

.project-thumb-link {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4;

  &:hover {opacity: 1;}
}

// Projects Small

.project-sml-blue, .project-sml-red {
  grid-column: span 1;
  grid-row: span 1;
  transition: all 0.4s ease-in-out;

  h3, h4, h5 {margin: 20px;}

  h5 {margin-bottom: 0px; font-size: 0.8em;}

  h3, h5 {bottom: 0;}
  h4 {margin-top: 46px; font-size: 1.05em;}
  h3 {margin-bottom: 28px; font-size: 1.25em;}

  &:hover {
    h3, h4, h5 {opacity: 1;}
    h4 {margin-top: 22px;}
    h5 {margin-bottom: 22px;}
    h3 {margin-bottom: 52px;}

    .project-thumbnail {opacity: 0.05; filter: grayscale(80%);}
  }
}

.project-sml-blue {
  background: $color-blue;
  &:hover .project-thumbnail {opacity: 0.2;}
}

.project-sml-red {
  background: $color-red;
  &:hover .project-thumbnail {opacity: 0.15;}
}

@media (max-width: 1040px) {
  .project-sml-blue, .project-sml-red {
    h3, h4, h5 {margin: 18px;}
    h4 {font-size: 0.9em; margin-top: 44px;}
    h3 {font-size: 1.1em; margin-bottom: 26px;}
    h5 {font-size: 0.75em; margin-bottom: 0px;}

    &:hover {
      h4 {margin-top: 20px;}
      h3 {margin-bottom: 46px;}
      h5 {margin-bottom: 20px;}
    }
  }
}

@media (max-width: 688px) {
  .project-sml-blue, .project-sml-red {
    h3, h4, h5 {margin: 12px;}
    h4 {margin-top: 34px; font-size: 0.8em;}
    h3 {line-height: 20px;font-size: 1em; margin-bottom: 22px;}
    h5 {font-size: 0.65em; margin-bottom: 0px;}

    &:hover {
      h4 {margin-top: 14px;}
      h3 {margin-bottom: 36px;}
      h5 {margin-bottom: 14px;}
    }
  }
}

.project-thumbnail {
  display: block;
  width: 100%;
  //height: 100%;
  align-self: center;
  object-fit: cover;
  transition: all 0.4s ease-in-out;
  opacity: 1;
}

// Grid Item Animation

.grid-item {
  box-shadow: 0 0.1rem 0.3rem transparent;
  animation: fadein 0.6s ease-out normal backwards;

  &:hover {
    transform: scale(1.015);
    z-index: 100;
    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.25);
  }
}

@keyframes fadein {
  0% {transform: scale(0);}
  100% {transform: scale(1);}
}

.grid-item1 {animation-delay: 200ms;}
.grid-item2 {animation-delay: 300ms;}
.grid-item3 {animation-delay: 400ms;}
.grid-item4 {animation-delay: 500ms;}
.grid-item5 {animation-delay: 600ms;}
.grid-item6 {animation-delay: 500ms;}
.grid-item7 {animation-delay: 600ms;}
.grid-item8 {animation-delay: 300ms;}
.grid-item9 {animation-delay: 400ms;}
.grid-item10 {animation-delay: 500ms;}
.grid-item11 {animation-delay: 600ms;}
.grid-item12 {animation-delay: 500ms;}
.grid-item13 {animation-delay: 600ms;}
.grid-item14 {animation-delay: 700ms;}
.grid-item15 {animation-delay: 600ms;}
.grid-item16 {animation-delay: 700ms;}
.grid-item17 {animation-delay: 800ms;}
.grid-item18 {animation-delay: 900ms;}
.grid-item19 {animation-delay: 1000ms;}
