.cs-grid-3 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.margin-bottom-50 {margin-bottom: 50px;}

.margin-tb {margin: 60px 0 100px 0;}

.grid-3-sb {justify-content: space-between;}

.cs-image {
    width: 31%;
    height: auto;
}

.cs-image-sb {width: 32%;}


.card-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32%;
}

.card-image-33 {
    width: 32.6%;
}

.mobile-svg {width: 80%;}

@media (max-width: 1280px) {
    .margin-tb {margin: 20px 0 60px 0;}
    .mobile-svg {width: 90%;}
}


@media (max-width: 1040px) {
    .mobile-svg {width: 100%;}
    .margin-tb {margin: 20px 0 40px 0;}
}

@media (max-width: 870px) {
    .img-50 {width: 49%;} 
}

@media (max-width: 688px) {
    .img-50 {width: 100%;} 
    #mobile3 {display: none;}
    .cs-image {width: 100%;}
    .card-image  {width: 100%;}
    .mobile-svg {width: 80%;}
    .cs-image-sb {width: 49%;}
    .margin-bottom-50 {margin-bottom: 20px;}
    .cs-last-img {display: none;}
}