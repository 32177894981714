// contact

.contact-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 13%;
  margin-bottom: 140px;
  transition: all 0.5s ease;

  @media (max-width: 1380px) {margin-top: 15%;}
  @media (max-width: 870px) {margin-top: 20%;}
  @media (max-width: 688px) {margin-top: 90px; margin-bottom: 50px;}
}

.contact-info {
  display: flex;
  flex-direction: column;
  width: 64%; margin-left: 6%;transition: all 0.5s ease;
  @media (max-width: 1380px) {width: 74%; margin-left: 4%;}
  @media (max-width: 870px) {margin-left: 0%; width: 72%;}
  @media (max-width: 688px) {width: 84%;}
}

.contact-content {
  display: flex;
  @media (max-width: 870px) {flex-direction: column;}
}

.contactTitle {
  color: $color-blue;
  line-height: 1.1em;
  font-size: 5em;
  margin-bottom: 50px;

  @media (max-width: 1380px) {font-size: 4.4em; margin-bottom: 38px;}
  @media (max-width: 1040px) {font-size: 3.6em; margin-bottom: 30px;}
  @media (max-width: 688px) {font-size: 2.8em; margin-bottom: 20px;}
  @media (max-width: 480px) {font-size: 2.4em; margin-bottom: 14px;}

}

.contact-form {
  width: 66%;
  padding-right: 120px;
  margin-bottom: 0;
  p {margin-bottom: 70px; transition: all 0.5s ease;}

  @media (max-width: 1380px) {width: 65%; padding-right: 70px; p {margin-bottom: 40px;}}
  @media (max-width: 870px) {width: 100%; padding-right: 0px;}

  @media (max-width: 688px) {
    margin-bottom: 0;
    p {margin-bottom: 22px;}
  }
}

.form-group {
  margin-bottom: 40px;
  color: $color-blue;

  input, textarea {
    padding-left: 0;
    @include ProximaNova-Bold;
    font-size: 1.125em;
    color: $color-blue;
    font-weight: normal;
    font-style: normal;
  }

  ::placeholder {color: $color-blue;}

  textarea {
    height: 100px; resize: none;}

  @media (max-width: 1380px) {margin-bottom: 30px; textarea {height: 100px; resize: none; margin-top: 6px;}}
  @media (max-width: 1040px) {input, textarea {font-size: 1em;}}
  @media (max-width: 688px) {margin-bottom: 22px; textarea {height: 80px; margin-top: 5px;}}
}

.form-label {
  font-size: 12px;
  color: #5e9bfc;
  margin: 0;
  display: block;
  opacity: 1;
  transition: 0.333s ease top, 0.333s ease opacity;
}

.form-control {
  width: 100%;
  height: 40px;
  color: $color-blue;
  background-color: $color-background;
  border-radius: 0;
  border-color: $color-red;
  border-width: 0 0 1px 0;
  border-style: none none solid none;
  box-shadow: none;

  &:focus {outline: 0;box-shadow: none;border-color: $color-blue;}
}

.submit-btn {
  display: flex;
  align-items: center;

  h4 {
    @include ProximaNova-Bold;
    padding: 0;
    margin: 0;
    margin-left: 30px;
  }
}
.btn-start {
  width: 200px;
  height: 50px;
  background-color: $color-background;
  border: 1px solid $color-red;
  @include ProximaNova-Bold;
  color: $color-red;
  font-size: 1.125em;
  line-height: inherit;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover, &:active, &:focus {
    background-color: $color-red; color: $color-white;
    outline: 0; box-shadow: none;
  }

  @media (max-width: 1040px) {width: 180px; height: 44px; font-size: 1em;}
}

.contactText {position: relative; opacity: 1;}

.contact-details {
  position: relative;

  h4 {@include ProximaNova-Bold; font-size: 1.125em; color: $color-red; margin-bottom: 6px;}

  h3 {font-size: 1.5em; margin-bottom: 26px;}

  a {
    position: relative;
    font-size: 1.5em;
    text-decoration: none;
    z-index: 1;
    color: $color-blue;
    transition: all 0.1s ease;

    &::before {
      content: '';
      padding: 0;
      position: absolute;
      margin-top: 14px;
      margin-left: -2px;
      height: 10px;
      background-color: $color-red;
      z-index: -1;
      transition: all .3s ease;
      width: 0px;
    }

    &:hover {width: 110%; &::before {width: 110%;}}
  }

  ul {list-style: none; padding: 0;margin: 0 auto; li {padding-bottom: 6px;}}

  @media (max-width: 1380px) {a, h3 {font-size: 1.25em !important;}}
  @media (max-width: 870px) {margin-top: 66px;}
}

.phone-title {margin-top: 22px;}

.phone-number {
  //font-size: 1.1em !important;
}

//Load Pop up
#load{
  margin-left: 30px;
  @include ProximaNova-Bold;
  font-size: 1.125em;

  @media (max-width: 688px) {margin-left: 24px; font-size: .9em;}
}
